import { ProjectDetailDto } from '@gamebase-web-ops/project';
import { Transfer } from 'antd';
import { useCallback, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import useProjectMenu from 'hooks/admin/useProjectMenu';
import { EProjectCodeType, ProjectCodeDto } from '@gamebase-web-ops/code';

interface IProp {
    project: ProjectDetailDto;
}

export default function ProjectMenuEdit({ project }: IProp) {
    const projectId = project.id;
    const { projectAllMenuList, projectMenuList, createProjectMenu, deleteProjectMenu } = useProjectMenu(projectId);
    const [sourceSelectedKey, setSourceSelectedKey] = useState<string[]>([]);
    const [targetSelectedKey, setTargetSelectedKey] = useState<string[]>([]);
    const dataSource = useMemo(() => {
        return projectAllMenuList.map(({ codeValue, desc }: ProjectCodeDto<string>) => ({
            key: codeValue,
            title: desc,
            description: desc,
        }));
    }, [projectAllMenuList]);

    const targetKeys = useMemo(() => {
        return projectMenuList.map(({ codeValue }: ProjectCodeDto<string>) => codeValue);
    }, [projectMenuList]);

    const addMenu = useCallback(
        async (selectedMenu: ProjectCodeDto<string>[]) => {
            if (!selectedMenu || !projectAllMenuList) return;
            createProjectMenu({
                projectId,
                codeData: selectedMenu.map((it) => {
                    return {
                        codeType: EProjectCodeType.PROJECT_MENU,
                        codeValue: it.codeValue,
                        desc: it.desc,
                        key: it.key,
                    };
                }),
            });
        },
        [createProjectMenu, projectAllMenuList, projectId],
    );

    const deleteMenu = useCallback(
        async (selectedMenu: { id: string }[]) => {
            if (!selectedMenu || !projectAllMenuList) return;
            deleteProjectMenu({
                projectId,
                ids: selectedMenu.map((it) => it.id),
                codeType: EProjectCodeType.PROJECT_MENU,
            });
        },
        [deleteProjectMenu, projectAllMenuList, projectId],
    );

    return (
        <section css={transfer}>
            <Transfer
                listStyle={{
                    height: 350,
                    width: '100%',
                }}
                showSelectAll={false}
                operations={['추가', '삭제']}
                dataSource={dataSource}
                targetKeys={targetKeys}
                render={(item) => item.title}
                onChange={(_, direction, moveKeys) => {
                    const moveKey = moveKeys;
                    if (direction === 'right') {
                        const selectedMenu = projectAllMenuList.filter(({ codeValue }: ProjectCodeDto<string>) =>
                            moveKey.includes(codeValue),
                        );
                        addMenu(selectedMenu);
                    } else {
                        const selectedMenu = projectMenuList.filter(({ codeValue }: ProjectCodeDto<string>) =>
                            moveKey.includes(codeValue),
                        );

                        deleteMenu(selectedMenu);
                    }
                }}
                onSelectChange={(sourceSelectedKeys, targetSelectedKeys) => {
                    setSourceSelectedKey(sourceSelectedKeys);
                    setTargetSelectedKey(targetSelectedKeys);
                }}
                selectedKeys={[...sourceSelectedKey, ...targetSelectedKey]}
            />
        </section>
    );
}

const transfer = css`
    .ant-transfer-list-header {
        display: none;
    }
    h1 {
        font-size: 18px;
        margin: 30px 0 10px;
    }
`;
