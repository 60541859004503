import { Switch, Route } from 'react-router-dom';
import { PageAuthViewController } from 'provider';
import {
    Project,
    RolePage,
    GameCreatePage,
    CrossPromotionPage,
    AccountDetail,
    Account,
    AppAdsPage,
    DeployEnvPage,
} from 'pages';
import { COMMON_PROJECT_ID } from 'constants/constants';
import { EServiceAuthLevel, EServiceAuthName } from '@gamebase-web-ops/authorization/lib/v2';
import ContentsMenu from 'pages/admin/ContentsMenu';
import { CommonLayout } from 'components';
import usePermissions from '../hooks/auth/use-permissions';
import { useMemo } from 'react';

export default function CommonRoute() {
    const { permission } = usePermissions(COMMON_PROJECT_ID);
    const permissionDtos = useMemo(() => {
        if (permission) {
            return [permission];
        }
        return [];
    }, [permission]);
    return (
        <Switch>
            <CommonLayout>
                <Route path="/common/siteManagement/contentsMenu" render={() => <ContentsMenu />} />
                <Route
                    path={['/common/siteManagement/project/:id', '/common/siteManagement/project']}
                    render={() => <Project />}
                />
                <Route
                    path="/common/setting/account/:id"
                    render={() => (
                        <PageAuthViewController
                            global={true}
                            auths={[{ service: 'account', level: EServiceAuthLevel.WRITE }]}
                            permissionDtos={permissionDtos}
                        >
                            <AccountDetail />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    exact
                    path="/common/setting/account"
                    render={() => (
                        <PageAuthViewController
                            global={true}
                            auths={[{ service: 'account', level: EServiceAuthLevel.READ }]}
                            permissionDtos={permissionDtos}
                        >
                            <Account />
                        </PageAuthViewController>
                    )}
                />
                <Route path="/common/setting/role" render={() => <RolePage />} />
                <Route
                    path={['/common/commonManagement/game/:id', '/common/commonManagement/game']}
                    render={() => (
                        <PageAuthViewController
                            global={true}
                            auths={[{ service: EServiceAuthName.GAME, level: EServiceAuthLevel.READ }]}
                            permissionDtos={permissionDtos}
                        >
                            <GameCreatePage />
                        </PageAuthViewController>
                    )}
                />
                <Route path="/common/commonManagement/deployEnv" render={() => <DeployEnvPage />} />
                <Route
                    exact
                    path="/common/commonManagement/cross-promotion"
                    render={() => (
                        <PageAuthViewController
                            global={true}
                            auths={[{ level: EServiceAuthLevel.WRITE, service: EServiceAuthName.CROSS_PROMOTION }]}
                            permissionDtos={permissionDtos}
                        >
                            <CrossPromotionPage />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    exact
                    path={['/common/appAds/app-ads', '/common/appAds/app-ads/:publisher']}
                    render={() => (
                        <PageAuthViewController
                            global={true}
                            auths={[{ level: EServiceAuthLevel.WRITE, service: EServiceAuthName.APP_ADS }]}
                            permissionDtos={permissionDtos}
                        >
                            <AppAdsPage />
                        </PageAuthViewController>
                    )}
                />
            </CommonLayout>
        </Switch>
    );
}
