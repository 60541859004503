import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { SettingRepository } from 'repository';

type LanguageKeys = keyof ReturnType<typeof SettingRepository.getLanguages>;

export default function useFormatMessage() {
    const { formatMessage } = useIntl();

    const messageFormatting = useCallback(
        (id: LanguageKeys, values?: any) => {
            return formatMessage(
                {
                    id,
                },
                values,
            );
        },
        [formatMessage],
    );

    return {
        messageFormatting,
    };
}
