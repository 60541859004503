import { GuildApplicantDto, GuildDto } from '@gamebase-web-ops/guild';
import { Button, Modal, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import ReactJson from 'react-json-view';
import { useCallback, useState } from 'react';
import ButtonGroup from 'antd/lib/button/button-group';
import useGuildApplicant from '../../../hooks/guild/use-guild-applicant';
import { useQueries } from '../../../hooks';
import styles from './guild.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export default function GuildApplicant({
    applicants,
    guildId,
    refetchGuild,
}: {
    guildId: string;
    applicants: GuildDto['applicantList'];
    refetchGuild: any;
}) {
    const { projectId } = useQueries();
    const { withdrawApplicant, isWithdrawApplicantLoading } = useGuildApplicant();
    const [isVisible, setVisible] = useState(false);
    const [selectData, setSelectData] = useState<GuildApplicantDto>();
    const deleteConfirm = useCallback(
        async (accountId: string) => {
            Modal.confirm({
                title: '가입 신청 취소',
                content: '가입 신청을 취소하시겠습니까?',
                type: 'warn',
                onOk: async () => {
                    await withdrawApplicant({ accountId, projectId, id: guildId });
                    refetchGuild();
                },
            });
        },
        [refetchGuild, withdrawApplicant, guildId, projectId],
    );

    const [selectedRow, setSelectedRow] = useState(-1);
    const columns: ColumnsType<GuildApplicantDto> = [
        {
            title: '아이디',
            dataIndex: 'accountId',
            key: 'accountId',
        },
        {
            title: '요청일',
            dataIndex: 'requestTime',
            key: 'requestTime',
            render(value) {
                return moment(value).format('YYYY-MM-DD HH:mm:ss');
            },
        },
        {
            title: '커스텀 데이터',
            dataIndex: 'etc',
            key: 'accountId',
            render(_, obj) {
                return (
                    <Button
                        size="middle"
                        onClick={() => {
                            setSelectData(obj);
                            setVisible(true);
                        }}
                    >
                        JSON
                    </Button>
                );
            },
        },
        {
            title: '',
            dataIndex: 'accountId',
            key: 'accountId',
            render(_, obj, index) {
                return (
                    <ButtonGroup>
                        <Button
                            size="middle"
                            onClick={() =>
                                window.open(
                                    `${window.location.origin}/project/user/userInfo/${obj.accountId}${window.location.search}`,
                                    '_blank',
                                )
                            }
                        >
                            상세
                        </Button>
                        <Button
                            size="middle"
                            danger
                            onClick={() => {
                                setSelectedRow(index);
                                deleteConfirm(obj.accountId);
                            }}
                            loading={isWithdrawApplicantLoading && selectedRow === index}
                        >
                            가입 신청 취소
                        </Button>
                    </ButtonGroup>
                );
            },
        },
    ];
    return (
        <>
            <Modal
                title="커스텀 데이터"
                open={isVisible}
                onCancel={() => setVisible(false)}
                okButtonProps={{ hidden: true }}
                width={800}
            >
                <ReactJson src={selectData?.etc ?? {}} enableClipboard={true}></ReactJson>
            </Modal>
            <Table
                columns={columns}
                dataSource={applicants}
                bordered
                pagination={{ pageSize: 8 }}
                className={cx('table-wrapper')}
                size="middle"
            ></Table>
        </>
    );
}
