import { useCallback, useEffect, useState } from 'react';
import { AuthorizationRepository, RoleRepository } from 'repository';
import { RoleCreateRequest, RoleUpdateRequest, RoleDeleteRequest } from '@gamebase-web-ops/role';
import { MyRoleDto, RoleListRequest } from '@gamebase-web-ops/authorization/lib/v2';

function useMyRole() {
    const LIMIT = 15;
    const [page, setPage] = useState(1);

    const [roles, setRoles] = useState<MyRoleDto[]>();
    const [totalCount, setTotalCount] = useState(0);
    const [filter, _setFilter] = useState<{ name?: string; description?: string }>();

    const mutate = useCallback(async () => {
        const { result } = await AuthorizationRepository.roleList(
            new RoleListRequest({ page, limit: LIMIT, ...filter }),
        );
        setRoles(result.data);
        setTotalCount(result.totalCount);
    }, [page, filter]);

    useEffect(() => {
        mutate();
    }, [mutate]);

    const createMyRole = useCallback(
        async (param: RoleCreateRequest) => {
            await RoleRepository.create(new RoleCreateRequest({ ...param }));
            mutate();
        },
        [mutate],
    );

    const editMyRole = useCallback(
        async (param: RoleUpdateRequest) => {
            await RoleRepository.update(new RoleUpdateRequest({ ...param }));
            mutate();
        },
        [mutate],
    );

    const deleteMyRole = useCallback(
        async (id: RoleDeleteRequest) => {
            await RoleRepository.delete(new RoleDeleteRequest({ ...id }));
            mutate();
        },
        [mutate],
    );

    const setFilter = useCallback((args: Pick<RoleListRequest, 'name' | 'description'>) => {
        const keys = Object.keys(args) as Array<'name' | 'description'>;
        keys.map((key) => {
            if (args[key] === '') {
                delete args[key];
            }
        });
        _setFilter(args);
        setPage(1);
    }, []);

    return {
        roles,
        createMyRole,
        editMyRole,
        deleteMyRole,
        LIMIT,
        page,
        setPage,
        totalCount,
        setFilter,
    };
}

export default useMyRole;
