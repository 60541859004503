import { useMutation } from 'react-query';
import { WithdrawMemberRequest } from '@gamebase-web-ops/guild';
import GuildRepository from '../../repository/guild.repository';
import { notificationPush } from '../../utils/notificationUtils';
import useFormatMessage from 'hooks/lang/useFormatMessage';

const useGuildApplicant = () => {
    const { messageFormatting } = useFormatMessage();

    const withdrawApplicantMutation = useMutation(
        (request: WithdrawMemberRequest) => {
            return GuildRepository.withdrawMember(request);
        },
        {
            onSuccess: () => {
                notificationPush({
                    title: '성공',
                    message: messageFormatting('SUCCESS_APPLICANT_CANCEL'),
                    status: 'success',
                });
            },
            onError: (err: any) => {
                notificationPush({
                    title: '실패',
                    message: `${messageFormatting('FAIL_APPLICANT_CANCEL')}\n(${err?.message ?? err?.code})`,
                    status: 'error',
                });
            },
        },
    );

    return {
        withdrawApplicant: withdrawApplicantMutation.mutateAsync,
        isWithdrawApplicantLoading: withdrawApplicantMutation.isLoading,
    };
};

export default useGuildApplicant;
