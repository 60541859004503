import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { ChangeGuildNameRequest, GuildInfoRequest, WithdrawRequest } from '@gamebase-web-ops/guild';
import GuildRepository from '../../repository/guild.repository';
import { notificationPush } from '../../utils/notificationUtils';
import useFormatMessage from 'hooks/lang/useFormatMessage';

export default function useGuild() {
    const { messageFormatting } = useFormatMessage();

    const [query, setQuery] = useState<GuildInfoRequest>();
    const { data, isLoading, refetch, isRefetching, isError } = useQuery(['guild-info', query], () =>
        query?.id ?? query?.name ? GuildRepository.info(new GuildInfoRequest({ ...query })) : null,
    );

    const changeNameMutation = useMutation(
        (request: ChangeGuildNameRequest) => {
            return GuildRepository.changeGuildName(request);
        },
        {
            onSuccess: () => {
                refetch();
                notificationPush({
                    title: '성공',
                    message: messageFormatting('SUCCESS_GUILD_NAME_EDIT'),
                    status: 'success',
                });
            },
            onError: () => {
                refetch();
                notificationPush({
                    title: '실패',
                    message: messageFormatting('FAIL_GUILD_NAME_EDIT'),
                    status: 'error',
                });
            },
        },
    );

    const withdrawMutation = useMutation(
        (request: WithdrawRequest) => {
            return GuildRepository.withdraw(request);
        },
        {
            onSuccess: () => {
                refetch();
                notificationPush({
                    title: '성공',
                    message: messageFormatting('SUCCESS_GUILD_WITHDRAW'),
                    status: 'success',
                });
            },
            onError: () => {
                notificationPush({
                    title: '실패',
                    message: messageFormatting('FAIL_GUILD_WITHDRAW'),
                    status: 'error',
                });
            },
        },
    );

    const { mutate: changeGuildName, isLoading: isNameChangeLoading } = changeNameMutation;
    const { mutate: withdraw, isLoading: isWithdrawLoading } = withdrawMutation;

    return {
        guild: data,
        isGuildLoading: isLoading,
        setGuildFilter: setQuery,
        changeGuildName,
        isNameChangeLoading,
        withdraw,
        isWithdrawLoading,
        refetchGuild: refetch,
        isRefetching,
        isGuildError: isError,
    };
}
