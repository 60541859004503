import { ECouponState } from '@gamebase-web-ops/coupon';
import { EMailRequestState } from '@gamebase-web-ops/mail';
import { EPushState } from '@gamebase-web-ops/push';
import { EMaintenanceRequestState } from '@gamebase-web-ops/maintenance';
import { NOTICE_STATE } from 'constants/constants';
import { ECrossPromotionState } from '@gamebase-web-ops/cross-promotion';
import { Status } from 'styles/common';
import { ERealtimeServerState } from '@gamebase-web-ops/project';

export const mailStatusColor = {
    [EMailRequestState.PENDING]: Status.pending,
    [EMailRequestState.SUCCESS]: Status.success,
    [EMailRequestState.FAIL]: Status.fail,
    [EMailRequestState.REQUEST]: Status.request,
};

export const noticeStatusColor = {
    [NOTICE_STATE.PENDING]: Status.pending,
    [NOTICE_STATE.WRITE_REQUEST]: Status.request,
    [NOTICE_STATE.WRITE_SUCCESS]: Status.success,
    [NOTICE_STATE.WRITE_FAIL]: Status.fail,
    [NOTICE_STATE.REMOVE_REQUEST]: Status.request,
    [NOTICE_STATE.REMOVE_SUCCESS]: Status.success,
    [NOTICE_STATE.REMOVE_FAIL]: Status.fail,
    [NOTICE_STATE.RESERVED]: Status.pending,
};

export const mainTenanceRequestStatusColor = {
    [EMaintenanceRequestState.PENDING]: Status.pending,
    [EMaintenanceRequestState.REQUEST]: Status.request,
    [EMaintenanceRequestState.SUCCESS]: Status.success,
    [EMaintenanceRequestState.FAIL]: Status.fail,
};

export const couponStatusColor = {
    [ECouponState.PENDING]: Status.pending,
    [ECouponState.ACTIVATE_REQUEST]: Status.request,
    [ECouponState.ACTIVATE_SUCCESS]: Status.success,
    [ECouponState.ACTIVATE_FAIL]: Status.fail,
    [ECouponState.CREATE_REQUEST]: Status.request,
    [ECouponState.CREATE_SUCCESS]: Status.success,
    [ECouponState.CREATE_FAIL]: Status.fail,
    [ECouponState.DEACTIVATE_REQUEST]: Status.request,
    [ECouponState.DEACTIVATE_SUCCESS]: Status.success,
    [ECouponState.DEACTIVATE_FAIL]: Status.fail,
    [ECouponState.UPDATE_REQUEST]: Status.request,
    [ECouponState.UPDATE_SUCCESS]: Status.success,
    [ECouponState.UPDATE_FAIL]: Status.fail,
};

export const pushStatusColor = {
    [EPushState.PENDING]: Status.pending,
    [EPushState.WRITE_REQEUST]: Status.request,
    [EPushState.WRITE_SUCCESS]: Status.success,
    [EPushState.WRITE_FAIL]: Status.fail,
    [EPushState.CLOSE_REQUEST]: Status.request,
    [EPushState.CLOSE_SUCCESS]: Status.success,
    [EPushState.CLOSE_FAIL]: Status.fail,
    [EPushState.PUBLISH]: Status.pending,
};

export const crossPromotionStatusColor = {
    [ECrossPromotionState.PENDING]: Status.pending,
    [ECrossPromotionState.WRITE_REQUEST]: Status.request,
    [ECrossPromotionState.WRITE_SUCCESS]: Status.success,
    [ECrossPromotionState.WRITE_FAIL]: Status.fail,
    [ECrossPromotionState.REMOVE_REQUEST]: Status.request,
    [ECrossPromotionState.REMOVE_SUCCESS]: Status.success,
    [ECrossPromotionState.REMOVE_FAIL]: Status.fail,
};

export const realTimeServerStatusColor = {
    [ERealtimeServerState.RUNNING]: Status.success,
    [ERealtimeServerState.CLOSING]: Status.fail,
};
