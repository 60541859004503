import { notificationPush } from 'utils/notificationUtils';
import { useCallback, useEffect, useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import CouponRepository from 'repository/Coupon.repository';
import {
    CouponListRequest,
    CouponCreateRequest,
    CouponDetailDto,
    CouponUpdateRequest,
    CouponActivateRequest,
    CouponDeactivateRequest,
    CouponInfoRequest,
} from '@gamebase-web-ops/coupon';
import { notificationAfterCompareToBefore } from 'utils/couponState';

export default function useCoupon(projectId: string) {
    const queryClient = useQueryClient();
    const [filter, setFilter] = useState({ projectId, page: 1, limit: 15 });
    const [selectedCouponId, setSelectedCouponId] = useState<string>('');
    const [selectedCoupon, setSelectedCoupon] = useState<CouponDetailDto>();
    const couponListQueryKey = ['couponList', { ...filter, projectId }] as const;

    const { data: couponList, isLoading } = useQuery(
        couponListQueryKey,
        ({ queryKey }) => {
            const [_, payload] = queryKey;
            return CouponRepository.list(new CouponListRequest(payload));
        },
        {
            isDataEqual: (oldData, newData): boolean => {
                if (oldData) {
                    if (oldData) {
                        oldData.result.data.map(({ id, state }) => {
                            const found = newData?.result.data.find((newInfo) => newInfo.id === id);
                            if (found && state !== found.state) notificationAfterCompareToBefore(state, found.state);
                        });
                    }
                }
                return false;
            },
        },
    );

    const createCouponMutation = useMutation(
        async (payload: CouponCreateRequest) => {
            await CouponRepository.create(new CouponCreateRequest(payload));
        },
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries(couponListQueryKey);
                notificationPush({
                    status: 'info',
                    message: '쿠폰 생성 요청을 전달하였습니다. \n시간이 소요될 수 있습니다.',
                });
            },
        },
    );

    const updateCouponMutation = useMutation(
        async (payload: CouponUpdateRequest) => {
            await CouponRepository.update(new CouponUpdateRequest(payload));
        },
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries(couponListQueryKey);
                notificationPush({
                    status: 'info',
                    message: '쿠폰 업데이트 요청을 전달하였습니다. \n시간이 소요될 수 있습니다.',
                });
            },
        },
    );

    const activateCouponMutation = useMutation(
        async (id: string) => {
            await CouponRepository.activate(new CouponActivateRequest({ projectId, id }));
        },
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries(couponListQueryKey);
            },
            onError: (error) => {
                console.error(error);
            },
        },
    );

    const deactivateCouponMutation = useMutation(
        async (id: string) => {
            await CouponRepository.deactivate(new CouponDeactivateRequest({ projectId, id }));
        },
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries(couponListQueryKey);
            },
            onError: (error) => {
                console.error(error);
            },
        },
    );

    const _requestSelectCoupon = useCallback(async () => {
        if (selectedCouponId) {
            const result = await CouponRepository.info(new CouponInfoRequest({ id: selectedCouponId, projectId }));
            setSelectedCoupon(result.result);
        }
    }, [projectId, selectedCouponId, setSelectedCoupon]);

    useEffect(() => {
        _requestSelectCoupon();
    }, [_requestSelectCoupon]);

    const { isLoading: isCouponCreating, mutateAsync: createCoupon } = createCouponMutation;
    const { isLoading: isCouponUpdating, mutateAsync: updateCoupon } = updateCouponMutation;
    const { mutate: activateCoupon } = activateCouponMutation;
    const { mutate: deactivateCoupon } = deactivateCouponMutation;

    return {
        setSelectedCouponId,
        createCoupon,
        updateCoupon,
        activateCoupon,
        deactivateCoupon,
        isCouponCreating,
        isCouponUpdating,
        filter,
        setFilter,
        couponList,
        selectedCoupon,
        setSelectedCoupon,
        isLoading,
    };
}
