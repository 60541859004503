import { Form, Input, Tabs, Button, Select, InputNumber, Radio, Descriptions, DatePicker } from 'antd';
import { Common } from 'styles/common';
import { css } from '@emotion/react';
import { useMemo, useState } from 'react';
import { EnterIgnoreForm, OsIcon } from 'components';
import { useFormatMessage, useFormRules } from 'hooks';
import { EMERGENCY_NOTICE_PLATFORM } from 'constants/constants';
import { emptyDeleteKey } from 'utils/object';
import { LuAsterisk } from 'react-icons/lu';
import moment from 'moment';

interface IFirstStep {
    onSubmit?: (data: any) => void;
    publishData: ICreateNotice | null;
    type: string;
    languages?: string[];
}

interface LangItem {
    forceRender: boolean;
    key: string;
    children: JSX.Element;
    label: string;
}

const styleAndroid = {
    color: Common.colors.android,
    fontSize: Common.fontSize.android,
};
const styleIOS = {
    color: Common.colors.ios,
    fontSize: Common.fontSize.ios,
};
function FirstStep({ onSubmit, publishData, type: NoticeType, languages }: IFirstStep) {
    const [startTime, setStartTime] = useState(moment(publishData?.startTime ?? moment().startOf('minute')));
    const [endTime, setEndTime] = useState(moment(publishData?.endTime ?? moment(startTime).add(30, 'minute')));
    const { formValidator } = useFormRules();

    const { messageFormatting } = useFormatMessage();
    const onFinish = async (values: any) => {
        if (onSubmit) {
            onSubmit({
                ...values,
                ...{
                    messages: emptyDeleteKey(values.messages || {}),
                    startTime: startTime,
                    endTime: endTime,
                    titles: emptyDeleteKey(values.titles || {}),
                },
            });
        }
    };

    const publishDataMessages = useMemo(() => {
        return publishData?.messages;
    }, [publishData]);

    const publishDataTitles = useMemo(() => {
        return publishData?.titles;
    }, [publishData]);

    const langItems: LangItem[] | undefined = languages?.map((lang) => {
        return {
            forceRender: true,
            key: lang,
            label: lang,
            children: (
                <>
                    <Descriptions size="small" bordered column={1} labelStyle={{ width: '150px' }}>
                        {NoticeType === 'normal' && (
                            <Descriptions.Item label="제목">
                                <Form.Item
                                    name={['titles', lang]}
                                    initialValue={publishDataTitles && publishDataTitles[lang as keyof IMessage]}
                                >
                                    <Input data-test_id={`title-${lang}`} />
                                </Form.Item>
                            </Descriptions.Item>
                        )}
                        <Descriptions.Item label="내용">
                            <Form.Item
                                name={['messages', lang]}
                                initialValue={publishDataMessages && publishDataMessages[lang as keyof IMessage]}
                            >
                                <Input.TextArea
                                    data-test_id={`message-${lang}`}
                                    showCount
                                    maxLength={10000}
                                    style={{ height: ['ingame', 'emergency'].includes(NoticeType) ? '10rem' : '30rem' }}
                                    onKeyDown={(e) => {
                                        if (e.key.toLowerCase() === 'enter') e.stopPropagation();
                                    }}
                                />
                            </Form.Item>
                        </Descriptions.Item>
                    </Descriptions>
                </>
            ),
        };
    });

    return (
        <EnterIgnoreForm name="control-hooks" onFinish={onFinish} layout="vertical">
            <Descriptions bordered column={2} labelStyle={{ width: 200 }} size="small">
                <Descriptions.Item
                    label={
                        <div css={label}>
                            <LuAsterisk css={required} />
                            {messageFormatting('TITLE')}
                        </div>
                    }
                    span={2}
                >
                    <Form.Item
                        name="description"
                        rules={formValidator.getRules('notice_title')}
                        initialValue={publishData?.description}
                    >
                        <Input />
                    </Form.Item>
                </Descriptions.Item>
                {NoticeType === 'ingame' && (
                    <>
                        <Descriptions.Item
                            label={
                                <div css={label}>
                                    <LuAsterisk css={required} />
                                    {messageFormatting('APP_VERSION')}
                                </div>
                            }
                            span={1}
                        >
                            <Form.Item
                                name="versions"
                                rules={formValidator.getRules('ingame_notice_version')}
                                initialValue={publishData?.versions}
                            >
                                <Select mode="tags" style={{ width: '100%' }} />
                            </Form.Item>
                        </Descriptions.Item>
                        <Descriptions.Item
                            span={1}
                            label={
                                <div css={label}>
                                    <LuAsterisk css={required} />
                                    {messageFormatting('REPEAT_NUM')}
                                </div>
                            }
                        >
                            <Form.Item
                                name="repeatNum"
                                initialValue={publishData?.repeatNum || 1}
                                style={{ width: '100%' }}
                            >
                                <InputNumber style={{ width: '100%' }} min={1} />
                            </Form.Item>
                        </Descriptions.Item>
                    </>
                )}
                <Descriptions.Item
                    span={1}
                    label={
                        <div css={label}>
                            <LuAsterisk css={required} />
                            {messageFormatting('START_AT')}
                        </div>
                    }
                >
                    <Form.Item name="startTime">
                        <DatePicker
                            style={{ width: '100%' }}
                            onChange={(dateString) => setStartTime(dateString ?? moment())}
                            defaultValue={startTime}
                            showTime
                        />
                    </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item
                    span={1}
                    label={
                        <div css={label}>
                            <LuAsterisk css={required} />
                            {messageFormatting('END_AT')}
                        </div>
                    }
                >
                    <Form.Item name="endTime">
                        <DatePicker
                            style={{ width: '100%' }}
                            defaultValue={endTime}
                            onChange={(dateString) => setEndTime(dateString ?? moment())}
                            showTime
                        />
                    </Form.Item>
                </Descriptions.Item>

                <Descriptions.Item label={messageFormatting('NOTICE_FIRST_LANGUAGE')} span={2}>
                    <Form.Item name="defaultLanguage" initialValue={publishData?.defaultLanguage || ''}>
                        <Select placeholder={messageFormatting('NOTICE_FIRST_LANGUAGE_MESSAGE')}>
                            <Select.Option key="" value="">
                                없음
                            </Select.Option>
                            {languages?.map((lang) => (
                                <Select.Option key={lang} value={lang}>
                                    {lang}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Descriptions.Item>
                {NoticeType === 'emergency' && (
                    <Descriptions.Item label="플랫폼" span={2}>
                        <Form.Item
                            name="platform"
                            initialValue={publishData?.platform || EMERGENCY_NOTICE_PLATFORM.BOTH}
                        >
                            <Radio.Group size="small">
                                <Radio value={EMERGENCY_NOTICE_PLATFORM.ANDROID} css={alignItemsCenter}>
                                    <OsIcon os="Android" style={styleAndroid} />
                                </Radio>
                                <Radio value={EMERGENCY_NOTICE_PLATFORM.iOS} css={alignItemsCenter}>
                                    <OsIcon os="iOS" style={styleIOS} />
                                </Radio>
                                <Radio value={EMERGENCY_NOTICE_PLATFORM.BOTH} css={alignItemsCenter}>
                                    <OsIcon os="Android" style={styleAndroid} />
                                    <OsIcon os="iOS" style={styleIOS} />
                                </Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Descriptions.Item>
                )}
                <Descriptions.Item label="본문" span={3}>
                    <Form.Item initialValue={publishData?.messages}>
                        <Tabs type="card" defaultActiveKey={publishData?.defaultLanguage} items={langItems}></Tabs>
                    </Form.Item>
                </Descriptions.Item>

                <Form.Item style={{ textAlign: 'right' }}>
                    <Button type="primary" htmlType="submit" data-test_id="toNextButton">
                        {messageFormatting('NEXT')}
                    </Button>
                </Form.Item>
            </Descriptions>
        </EnterIgnoreForm>
    );
}

export default FirstStep;

const alignItemsCenter = css`
    align-items: center;
`;

const label = css`
    display: inline-flex;
    align-items: center;
`;

const required = css`
    color: red;
`;
