import { useMutation } from 'react-query';
import { WithdrawMemberRequest } from '@gamebase-web-ops/guild';
import GuildRepository from '../../repository/guild.repository';
import { notificationPush } from '../../utils/notificationUtils';
import useFormatMessage from 'hooks/lang/useFormatMessage';

const useGuildMember = () => {
    const { messageFormatting } = useFormatMessage();

    const withdrawMemberMutation = useMutation(
        (request: WithdrawMemberRequest) => {
            return GuildRepository.withdrawMember(request);
        },
        {
            onSuccess: () => {
                notificationPush({
                    title: '성공',
                    message: messageFormatting('SUCCESS_MEMBER_WITHDRAW'),
                    status: 'success',
                });
            },
            onError: (err: any) => {
                notificationPush({
                    title: '실패',
                    message: `${messageFormatting('FAIL_MEMBER_WITHDRAW')}\n(${err?.message ?? err?.code})`,
                    status: 'error',
                });
            },
        },
    );

    return {
        withdrawMember: withdrawMemberMutation.mutateAsync,
        isWithdrawMemberLoading: withdrawMemberMutation.isLoading,
    };
};

export default useGuildMember;
