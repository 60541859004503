import { IsDate, IsIn, IsNotEmpty, IsNumber, IsOptional, IsString } from 'class-validator';
import { EMERGENCY_NOTICE_PLATFORM, NOTICE_STATE, NOTICE_TYPE } from 'constants/constants';

import { IsExcuteTest } from './customDecorators';

export class ICreateNoticePost {
    constructor(param: ICreateNoticePost) {
        this.startTime = new Date(param.startTime);
        this.endTime = new Date(param.endTime);
        this.messages = param.messages;
        this.versions = param.versions;

        this.type = param.type;
        this.defaultLanguage = param.defaultLanguage;
        this.titles = param.titles;
        this.repeatNum = param.repeatNum;
        this.noticePlatform = param.noticePlatform;
    }

    @IsOptional()
    @IsString()
    defaultLanguage: string;

    @IsNotEmpty()
    @IsDate()
    @IsExcuteTest<ICreateNoticePost>(({ endTime }, startTime) => startTime.valueOf() < endTime.valueOf(), {
        message: 'START_MORE_THEN_END',
    })
    startTime: Date;

    @IsNotEmpty()
    @IsDate()
    @IsExcuteTest<ICreateNoticePost>(
        ({ startTime, endTime }) => {
            const today = new Date();
            today.setHours(0);
            today.setMinutes(0);
            today.setSeconds(0);
            today.setMilliseconds(0);
            const toDayValue = today.valueOf();
            return startTime.valueOf() > toDayValue && endTime.valueOf() > toDayValue;
        },
        {
            message: '현재 날짜보다 이전으로 입력 할 수 없습니다.',
        },
    )
    endTime: Date;

    @IsNotEmpty()
    @IsExcuteTest<ICreateNoticePost>(
        ({ messages }) => {
            const keys = Object.keys(messages);
            const regexp = new RegExp('["]');
            return !keys.reduce<boolean>((acc, key) => acc || regexp.test(messages[key] as any), false);
        },
        {
            message: '큰따옴표(")를 사용하실수 없습니다.',
        },
    )
    messages: IMessage;

    @IsOptional()
    @IsExcuteTest<ICreateNoticePost>(
        ({ titles }) => {
            if (titles) {
                const keys = Object.keys(titles);
                const regexp = new RegExp('["]');
                return !keys.reduce<boolean>((acc, key) => acc || regexp.test(titles[key] as any), false);
            }
            return true;
        },
        {
            message: '큰따옴표(")를 사용하실수 없습니다.',
        },
    )
    @IsExcuteTest<ICreateNoticePost>(
        ({ type, titles }) => {
            if (type === NOTICE_TYPE.NORMAL) {
                return Object.keys(titles || {}).length > 0;
            }
            return true;
        },
        {
            message: '언어별 제목을 설정해주세요.',
        },
    )
    titles?: IMessage;

    @IsNumber()
    @IsIn([NOTICE_TYPE.URGENT, NOTICE_TYPE.NORMAL, NOTICE_TYPE.INGAME])
    type: NOTICE_TYPE;

    @IsOptional()
    @IsNumber()
    repeatNum?: number;

    @IsOptional()
    @IsIn([EMERGENCY_NOTICE_PLATFORM.BOTH, EMERGENCY_NOTICE_PLATFORM.ANDROID, EMERGENCY_NOTICE_PLATFORM.iOS])
    noticePlatform?: EMERGENCY_NOTICE_PLATFORM;

    @IsString({ each: true })
    @IsOptional()
    versions: string[];
}
