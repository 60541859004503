import { Common } from '../../../styles/common';
import { css } from '@emotion/react';
import { useForm } from 'antd/lib/form/Form';
import { Button, Form, Input, Select } from 'antd';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { FaSearch } from 'react-icons/fa';

export default function GuildSearch({ onSubmit }: { onSubmit: (request: { id?: string; name?: string }) => void }) {
    const [form] = useForm();
    const { id } = useParams<{ id?: string }>();

    const onSearch = useCallback(() => {
        const { type, value }: { type: 'name' | 'id'; value: string } = form.getFieldsValue();

        switch (type) {
            case 'name':
                return onSubmit({ name: value });
            case 'id':
                return onSubmit({ id: value });
        }
    }, [form, onSubmit]);

    return (
        <Form form={form} onSubmitCapture={onSearch}>
            <div css={wrapper}>
                <Form.Item name="type" initialValue="name" style={{ width: '150px' }}>
                    <Select size="large">
                        <Select.Option value={'name'}>길드명</Select.Option>
                        <Select.Option value={'id'}>길드 아이디</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item name="value" initialValue={id ?? ''} style={{ width: '350px' }}>
                    <Input
                        size="large"
                        onChange={(e) => {
                            e.target.value.match(/^[a-z0-9]{32}$/g) && form.setFieldValue('type', 'id');
                        }}
                    />
                </Form.Item>
                <Button type="primary" htmlType="submit" size="large">
                    <FaSearch />
                </Button>
            </div>
        </Form>
    );
}

const wrapper = css`
    display: flex;
    justify-content: end;
    ${Common.searchWrapper}
    margin-bottom: 15px;
`;
