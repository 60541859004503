import { notificationPush } from 'utils/notificationUtils';
import { PolicySettingRepository } from 'repository';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { policySettingInfoKey, policyDefaultAgreementSettingKey } from 'constants/queryKeys';
import {
    CreatePolicySettingRequest,
    UpdatePolicySettingRequest,
    DeployPolicySettingRequest,
} from '@gamebase-web-ops/policy-setting';
import useFormatMessage from 'hooks/lang/useFormatMessage';

export default function usePolicySetting(projectId: string) {
    const queryClient = useQueryClient();
    const { messageFormatting } = useFormatMessage();
    const getPolicySetting = () => {
        const { data, isLoading } = useQuery(policySettingInfoKey({ projectId }), ({ queryKey }) => {
            const [_, payload] = queryKey;
            return PolicySettingRepository.info({ ...payload });
        });
        return { data, isLoading };
    };

    const getDefaultPolicyAgreementSetting = () => {
        const { data } = useQuery(policyDefaultAgreementSettingKey(), () => {
            return PolicySettingRepository.allDefaultPolicyAgreementSetting();
        });
        return { data };
    };

    const createMutation = useMutation(
        (payload: CreatePolicySettingRequest) =>
            PolicySettingRepository.create(new CreatePolicySettingRequest({ ...payload })),
        {
            onSuccess: () => {
                notificationPush({ status: 'success', message: messageFormatting('SUCCESS_POLICY_DATA_CREATE') });
                return queryClient.invalidateQueries(policySettingInfoKey({ projectId }));
            },
        },
    );

    const updataMutation = useMutation(
        (payload: UpdatePolicySettingRequest) =>
            PolicySettingRepository.update(new UpdatePolicySettingRequest({ ...payload })),
        {
            onSuccess: () => {
                notificationPush({ status: 'success', message: messageFormatting('SUCCESS_POLICY_DATA_EDIT') });
                return queryClient.invalidateQueries(policySettingInfoKey({ projectId }));
            },
        },
    );

    const deployMutation = useMutation(
        (payload: DeployPolicySettingRequest) =>
            PolicySettingRepository.deploy(new DeployPolicySettingRequest({ ...payload })),
        {
            onSuccess: () => {
                notificationPush({ status: 'success', message: messageFormatting('SUCCESS_JSON_DATA_DEPLOY') });
            },
        },
    );

    const { mutate: createMutate, isSuccess: createSuccess } = createMutation;
    const { mutate: updateMutate, isSuccess: updataSuccess } = updataMutation;
    const { mutate: deployMutate, isSuccess: deploySuccess } = deployMutation;

    return {
        getPolicySetting,
        getDefaultPolicyAgreementSetting,
        createMutate,
        updateMutate,
        deployMutate,
        createSuccess,
        updataSuccess,
        deploySuccess,
    };
}
