import { lazy } from 'react';

export const Home = lazy(() => import('./Home'));

export const Account = lazy(() => import('./account/Account'));
export const ContentsMenu = lazy(() => import('./admin/ContentsMenu'));
export const Project = lazy(() => import('./admin/Project'));
export const RolePage = lazy(() => import('./admin/Role'));
export const CrossPromotionPage = lazy(() => import('./crossPromotion'));
export const MyPage = lazy(() => import('./myPage/MyPage'));
export const ItemPage = lazy(() => import('./project/Item'));
export const AccountDetail = lazy(() => import('./account/AccountDetail'));
export const ProjectDashBoard = lazy(() => import('./project/ProjectDashBoard'));
export const MailPage = lazy(() => import('./project/mail/Mail'));
export const UserPage = lazy(() => import('./project/user/User'));
export const NoticePage = lazy(() => import('./project/notice/Notice'));
export const AccountMerge = lazy(() => import('./project/accountMerge'));
export const GameSystemPage = lazy(() => import('./project/gameSystem/GameSytem'));
export const LogPage = lazy(() => import('./project/log/Log'));
export const VoidedPurchasePage = lazy(() => import('./project/VoidedPurchase'));
export const ReportPage = lazy(() => import('./project/report'));
export const OrderPage = lazy(() => import('./project/order/Order'));
export const BlockUserPage = lazy(() => import('./project/BlockUser'));
export const AbusingUserPage = lazy(() => import('./project/abusingUser/AbusingUser'));
export const WebviewEventPage = lazy(() => import('./project/event/WebviewEvent'));
export const PromotionPage = lazy(() => import('./project/promotion'));
export const GameCreatePage = lazy(() => import('./internalBusiness/GameCreate'));
export const CouponPage = lazy(() => import('./project/coupon/Coupon'));
export const PushPage = lazy(() => import('./project/push/Push'));
export const PolicyPage = lazy(() => import('./project/policy/Policy'));
export const RealTimeServerPage = lazy(() => import('./project/realTimeServer/RealTimeServer'));
export const IngameServerPage = lazy(() => import('./project/ingameMaintenance/IngameMaintenance'));
export const RangkingPage = lazy(() => import('./project/ranking'));
export const MetaDataPage = lazy(() => import('./marketing/GameMetaDataMaintenance'));
export const LoginServerPage = lazy(() => import('./development/loginServer'));
export const AppVersionPage = lazy(() => import('./project/appVersion'));
export const AppAdsPage = lazy(() => import('./AppAds'));
export const AppReviewPage = lazy(() => import('./project/appReview'));
export const DeployEnvPage = lazy(() => import('./DeployEnv'));
export const ChattingPage = lazy(() => import('./project/Chatting'));
export const VideoPage = lazy(() => import('./project/video'));
export const CommunityPage = lazy(() => import('./project/community'));
export const PlayerSearchPage = lazy(() => import('./project/playerSearch'));

export { default as LoginPage } from './Login';
export { default as ProjectListPage } from './ProjectList';
