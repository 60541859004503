import { GuildDto, GuildMemberDto } from '@gamebase-web-ops/guild';
import { Button, Form, Input, Modal, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { useCallback, useState } from 'react';
import ReactJson from 'react-json-view';
import { FaSearch } from 'react-icons/fa';
import { css } from '@emotion/react';
import { Common } from '../../../styles/common';
import { useForm } from 'antd/lib/form/Form';
import useGuildMember from '../../../hooks/guild/use-guild-member';
import { useQueries, useScreenLock } from '../../../hooks';
import useGuildMaster from '../../../hooks/guild/use-guild-master';
import classNames from 'classnames/bind';
import styles from './guild.module.scss';

const cx = classNames.bind(styles);

export default function GuildMember({
    memberList,
    isModalOpen = true,
    masterId,
    guildId,
    refetchGuild,
}: {
    memberList: GuildDto['memberList'];
    isModalOpen?: boolean;
    masterId: string;
    guildId: string;
    refetchGuild: any;
}) {
    const { withdrawMember, isWithdrawMemberLoading } = useGuildMember();
    const { changeMaster, isChangeMasterLoading } = useGuildMaster();
    const { projectId } = useQueries();
    const [isVisible, setVisible] = useState(false);
    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const [selectData, setSelectData] = useState<GuildMemberDto>();
    const deleteConfirm = useCallback(
        async (accountId: string) => {
            Modal.confirm({
                title: '탈퇴',
                content: '해당 계정을 길드에서 제외시키겠습니까?',
                onOk: async () => {
                    await withdrawMember({ accountId, projectId, id: guildId });
                    refetchGuild();
                },
            });
        },
        [withdrawMember, projectId, guildId, refetchGuild],
    );
    const changeMasterConfirm = useCallback(
        async (accountId: string) => {
            Modal.confirm({
                title: '길드 마스터 변경',
                content: (
                    <div>
                        <div>
                            기존: <b>{masterId}</b>
                        </div>
                        <div>
                            변경: <b>{accountId}</b>
                        </div>
                        길드 마스터를 변경하시겠습니까?
                    </div>
                ),
                onOk: async () => {
                    await changeMaster({
                        oldMasterAccountId: masterId,
                        newMasterAccountId: accountId,
                        projectId,
                        id: guildId,
                    });

                    refetchGuild();
                },
            });
        },
        [masterId, changeMaster, projectId, guildId, refetchGuild],
    );
    const [form] = useForm();
    const [selectedRow, setSelectedRow] = useState(-1);
    const columns: ColumnsType<GuildMemberDto> = [
        {
            title: '아이디',
            dataIndex: 'accountId',
            key: 'accountId',
            onFilter(value, record) {
                return record.accountId === value;
            },
            filterDropdownOpen: isModalOpen && isDropdownVisible,
            onFilterDropdownOpenChange(change) {
                setDropdownVisible(change);
            },
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
                return (
                    <Form form={form}>
                        <div css={searchWrapper}>
                            <Form.Item name="accountId">
                                <Input value={selectedKeys[0]} />
                            </Form.Item>
                            <Button
                                onClick={() => {
                                    setSelectedKeys([form.getFieldValue('accountId')]);
                                    confirm({ closeDropdown: true });
                                }}
                                size="middle"
                                type="primary"
                                htmlType="submit"
                            >
                                <FaSearch />
                            </Button>
                            <Button
                                onClick={() => {
                                    form.setFieldValue('accountId', '');
                                    clearFilters?.();
                                    confirm({ closeDropdown: true });
                                }}
                                size="middle"
                                danger
                            >
                                초기화
                            </Button>
                        </div>
                    </Form>
                );
            },
        },
        {
            title: '가입일',
            dataIndex: 'joinTime',
            key: 'accountId',
            render(value: string) {
                return moment(value).format('YYYY-MM-DD HH:mm:ss');
            },
        },
        {
            title: '길드 마스터',
            dataIndex: 'isMaster',
            filters: [
                {
                    text: 'Y',
                    value: true,
                },
                {
                    text: 'N',
                    value: false,
                },
            ],
            onFilter(value, record) {
                return record.isMaster === value;
            },
            render(value: boolean) {
                return value ? 'Y' : 'N';
            },
        },
        {
            title: '커스텀 데이터',
            dataIndex: 'etc',
            key: 'accountId',
            render(_: string, obj: GuildMemberDto) {
                return (
                    <Button
                        onClick={() => {
                            setSelectData(obj);
                            setVisible(true);
                        }}
                    >
                        JSON
                    </Button>
                );
            },
        },
        {
            title: '',
            key: 'accountId',
            render(_, obj: GuildMemberDto, index) {
                return (
                    <div>
                        <Button
                            onClick={() =>
                                window.open(
                                    `${window.location.origin}/project/user/userInfo/${obj.accountId}${window.location.search}`,
                                    '_blank',
                                )
                            }
                        >
                            상세
                        </Button>
                        <Button
                            danger
                            onClick={() => {
                                setSelectedRow(index);
                                return deleteConfirm(obj.accountId);
                            }}
                            loading={isWithdrawMemberLoading && selectedRow === index}
                            disabled={obj.accountId === masterId}
                        >
                            추방
                        </Button>
                        {!obj.isMaster && (
                            <Button
                                onClick={() => {
                                    setSelectedRow(index);
                                    return changeMasterConfirm(obj.accountId);
                                }}
                                loading={isChangeMasterLoading && selectedRow === index}
                            >
                                {' '}
                                마스터 부여
                            </Button>
                        )}
                    </div>
                );
            },
        },
    ];
    return (
        <>
            <Modal
                title="커스텀 데이터"
                open={isVisible}
                onCancel={() => setVisible(false)}
                okButtonProps={{ hidden: true }}
                cancelText="닫기"
            >
                <ReactJson src={selectData?.etc ?? {}} enableClipboard={true}></ReactJson>
            </Modal>
            <Table
                className={cx('table-wrapper')}
                columns={columns}
                dataSource={memberList}
                pagination={{ pageSize: 8 }}
                bordered={true}
                size="middle"
                style={{ boxShadow: 'none' }}
            ></Table>
        </>
    );
}

const searchWrapper = css`
    display: flex;
    ${Common.searchWrapper}
`;
