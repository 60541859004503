import { useState } from 'react';
import { Authorization2Repository, Role2Repository } from 'repository';
import { RoleListRequest } from '@gamebase-web-ops/authorization/lib/v2';
import { RoleCreateRequest, RoleUpdateRequest, RoleDeleteRequest } from '@gamebase-web-ops/role/lib/v2';
import { useMutation, useQuery } from 'react-query';
import { roleListKey } from 'constants/queryKeys';
import { notificationPush } from 'utils/notificationUtils';
import { PAGE_SIZE, DEFAULT_PAGE } from 'utils/pagination';
import useFormatMessage from 'hooks/lang/useFormatMessage';

export default function useMyRole2() {
    const { messageFormatting } = useFormatMessage();
    const [totalCount, setTotalCount] = useState(0);
    const [filter, setFilter] = useState<RoleListRequest>({
        page: DEFAULT_PAGE,
        limit: PAGE_SIZE,
    });

    const { data: roles } = useQuery(roleListKey({ ...filter }), async () => {
        const { result } = await Authorization2Repository.roleList(new RoleListRequest({ ...filter }));
        setTotalCount(result.totalCount);
        return result.data;
    });

    const createRoleMutation = useMutation(
        async (payload: RoleCreateRequest) => {
            const res = await Role2Repository.create(new RoleCreateRequest(payload));
            return res;
        },
        {
            onSuccess: () => {
                notificationPush({ status: 'success', message: messageFormatting('SUCCESS_ROLE_CREATE') });
            },
            onError: () => {
                notificationPush({ status: 'error', message: messageFormatting('FAIL_ROLE_CREATE') });
            },
        },
    );

    const updateRoleMutation = useMutation(
        async (payload: RoleUpdateRequest) => {
            const res = await Role2Repository.update(new RoleUpdateRequest(payload));
            return res;
        },
        {
            onSuccess: () => {
                notificationPush({ status: 'success', message: messageFormatting('SUCCESS_ROLE_EDIT') });
            },
            onError: () => {
                notificationPush({ status: 'error', message: messageFormatting('FAIL_ROLE_EDIT') });
            },
        },
    );

    const deleteRoleMutation = useMutation(
        async (payload: RoleDeleteRequest) => {
            const res = await Role2Repository.delete(new RoleDeleteRequest(payload));
            return res;
        },
        {
            onSuccess: () => {
                notificationPush({ status: 'success', message: messageFormatting('SUCCESS_ROLE_DELETE') });
            },
            onError: () => {
                notificationPush({ status: 'error', message: messageFormatting('FAIL_ROLE_DELETE') });
            },
        },
    );

    const { mutateAsync: createMyRole } = createRoleMutation;
    const { mutateAsync: updateMyRole } = updateRoleMutation;
    const { mutateAsync: deleteMyRole } = deleteRoleMutation;
    return {
        roles,
        filter,
        createMyRole,
        updateMyRole,
        deleteMyRole,
        totalCount,
        setFilter,
    };
}
