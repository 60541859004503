import { useMutation } from 'react-query';
import { ChangeGuildMasterRequest } from '@gamebase-web-ops/guild';
import GuildRepository from '../../repository/guild.repository';
import { notificationPush } from '../../utils/notificationUtils';
import useFormatMessage from 'hooks/lang/useFormatMessage';

const useGuildMaster = () => {
    const { messageFormatting } = useFormatMessage();

    const changeMasterMutation = useMutation(
        (request: ChangeGuildMasterRequest) => {
            return GuildRepository.changeGuildMaster(request);
        },
        {
            onSuccess: () => {
                notificationPush({
                    title: '성공',
                    message: messageFormatting('SUCCESS_GUILD_MASTER_EDIT'),
                    status: 'success',
                });
            },
            onError: (err: any) => {
                notificationPush({
                    title: '실패',
                    message: `${messageFormatting('FAIL_GUILD_MASTER_EDIT')}\n(${err.message ?? err.code})`,
                    status: 'error',
                });
            },
        },
    );
    return {
        changeMaster: changeMasterMutation.mutateAsync,
        isChangeMasterLoading: changeMasterMutation.isLoading,
    };
};

export default useGuildMaster;
