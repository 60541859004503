import React, { memo, Suspense } from 'react';
import { useQueries, useProjectDetail } from 'hooks';
import { LoadingSpin } from 'components';
import NoResult from 'components/common/NoResult';
import { COMMON_PROJECT_ID } from '../constants/constants';
import { EServiceAuthLevel } from '@gamebase-web-ops/authorization/lib/v2';

/**
 * Route 페이지에 대한 권한을 체크 하는 컴포넌트
 */
const PageAuthViewController: React.FC<IRouteController> = ({ children, permissionDtos, auths, global = false }) => {
    const { projectId } = useQueries();
    const { projectManagers } = useProjectDetail(projectId);

    const permissionDto = permissionDtos.find((it) => {
        return it.projectId === (global ? COMMON_PROJECT_ID : projectId);
    });

    if (
        !permissionDto?.isMaster &&
        !permissionDto?.isManager &&
        !(auths ?? [])?.every((it) =>
            permissionDto?.permissions.find(
                (permission) =>
                    permission.service === it.service && permission.level >= (it.level ?? EServiceAuthLevel.READ),
            ),
        )
    ) {
        const managers = projectManagers?.map((manager) => `${manager}님`).join(', ');
        const desc =
            projectId && managers
                ? `접근 권한이 없습니다. \n프로젝트 담당자 ${managers}께 문의 주세요.`
                : '접근 권한이 없습니다. \n게임베이스팀 Web파트로 문의 주세요.';

        return <NoResult description={desc} status="unauthorized" />;
    }

    return (
        <Suspense fallback={<LoadingSpin />}>
            {React.Children.map(children, (child) =>
                React.cloneElement(child as any, {
                    permissionDtos: permissionDtos,
                    auths,
                }),
            )}
        </Suspense>
    );
};

export default memo(PageAuthViewController);
