import { GameInfoRequest } from '@gamebase-web-ops/game';
import { gameDataInfoKey } from 'constants/queryKeys';
import { useQuery } from 'react-query';
import { GameRepository } from 'repository';

const useGameInfoModel = (id: string | undefined) => {
    return useQuery(gameDataInfoKey({ id }), () => (id ? GameRepository.info(new GameInfoRequest({ id })) : null));
};

export default useGameInfoModel;
