import { GuildDto, EJoinType } from '@gamebase-web-ops/guild';
import { Button, Descriptions, Modal, Layout, Tabs, Dropdown, Input, Form } from 'antd';
import moment from 'moment';
import ReactJson from 'react-json-view';
import { useCallback, useState } from 'react';
import { css } from '@emotion/react';
import { useForm } from 'antd/lib/form/Form';

export default function GuildInfo({
    guildDto,
    onNameChange,
    isNameChangeLoading,
    withdraw,
    isWithdrawLoading,
}: {
    guildDto: GuildDto;
    onNameChange: (name: string) => void;
    isNameChangeLoading: boolean;
    withdraw: () => void;
    isWithdrawLoading: boolean;
}) {
    const [changeNameOpen, setChangeNameOpen] = useState(false);
    const [showGuildEtc, setShowGuildEtc] = useState(false);
    const [nameForm] = useForm();
    const nameChangeHandler = useCallback(() => {
        const { name } = nameForm.getFieldsValue();

        if (name) {
            onNameChange(name);
        }

        setChangeNameOpen(false);
        nameForm.setFieldValue('name', '');
    }, [onNameChange, nameForm]);

    const withdrawHander = useCallback(() => {
        Modal.confirm({
            title: `${guildDto.name} 길드 해체`,
            content: '길드를 해체하시겠습니까?',
            type: 'warn',
            onOk: () => {
                withdraw();
            },
            okButtonProps: {
                danger: true,
                type: 'primary',
            },
            okText: '해체',
            cancelText: '취소',
        });
    }, [withdraw, guildDto.name]);

    return (
        <div css={wrapper}>
            <Descriptions
                column={2}
                size="default"
                bordered
                layout="horizontal"
                labelStyle={{ fontWeight: 600, width: '10%' }}
                contentStyle={{ width: '40%' }}
            >
                <Descriptions.Item label="길드 아이디">
                    <div css={flex}>
                        {guildDto.id}
                        <Button danger type="primary" onClick={withdrawHander} loading={isWithdrawLoading}>
                            해체
                        </Button>
                    </div>
                </Descriptions.Item>
                <Descriptions.Item label="생성일">
                    {moment(guildDto.createTime).format('YYYY-MM-DD HH:mm:ss')}
                </Descriptions.Item>
                <Descriptions.Item label="길드명">
                    <div css={flex}>
                        {guildDto.name}
                        <Dropdown
                            open={changeNameOpen}
                            menu={{
                                items: [
                                    {
                                        label: (
                                            <Form form={nameForm} onSubmitCapture={nameChangeHandler}>
                                                <Form.Item name="name">
                                                    <Input></Input>
                                                </Form.Item>
                                                <Button type="primary" htmlType="submit" loading={isNameChangeLoading}>
                                                    확인
                                                </Button>
                                                <Button
                                                    danger
                                                    onClick={() => {
                                                        nameForm.setFieldValue('name', '');
                                                        setChangeNameOpen(false);
                                                    }}
                                                >
                                                    취소
                                                </Button>
                                            </Form>
                                        ),
                                        key: 'input',
                                    },
                                ],
                            }}
                        >
                            <Button onClick={() => setChangeNameOpen(true)} loading={isNameChangeLoading}>
                                변경
                            </Button>
                        </Dropdown>
                    </div>
                </Descriptions.Item>
                <Descriptions.Item label="길드 마스터">
                    <div css={flex}>
                        {guildDto.masterName}
                        <Button
                            onClick={() => {
                                window.open(
                                    `${window.location.origin}/project/user/userInfo/${guildDto.masterAccountId}${window.location.search}`,
                                    '_blank',
                                );
                            }}
                        >
                            상세
                        </Button>
                    </div>
                </Descriptions.Item>

                <Descriptions.Item label="길드 인원">
                    <div css={flex}>
                        <span>{guildDto.memberList?.length} 명 </span>
                    </div>
                </Descriptions.Item>
                <Descriptions.Item label="가입 요청">
                    <div css={flex}>
                        <span>{guildDto.applicantList?.length} 명 </span>
                    </div>
                </Descriptions.Item>

                <Descriptions.Item label="가입 유형">{guildDto.joinType}</Descriptions.Item>

                <Descriptions.Item label="커스텀 정보">
                    <Button onClick={() => setShowGuildEtc(true)}>JSON</Button>
                </Descriptions.Item>
            </Descriptions>
            <Modal
                width={1000}
                open={showGuildEtc}
                onCancel={() => setShowGuildEtc(false)}
                maskClosable={false}
                cancelText="닫기"
                okButtonProps={{ hidden: true }}
            >
                <ReactJson src={guildDto.etc ?? {}}></ReactJson>
            </Modal>
        </div>
    );
}

const flex = css`
    display: flex;
    justify-content: space-between;
`;

const wrapper = css``;
