import imageCompression from 'browser-image-compression';
import { useCallback } from 'react';
import { SettingRepository } from 'repository';

const compressionOptions = {
    maxSizeMB: Number.POSITIVE_INFINITY,
    useWebWorker: true,
    initialQuality: 0.5,
} as const;

/**
 * (!) 사용하고 있지 않음
 */
export default function useFileUpload() {
    /**
     * @param formData: FormData<{ file: File }>
     */
    const upload = useCallback(async (file: File, path: string) => {
        const formData = new FormData();
        const compressedFile = await imageCompression(file, { ...compressionOptions, fileType: file.type });
        formData.set('file', new File([compressedFile], file.name));
        formData.set('path', path);
        const url = await SettingRepository.uploadFile(formData);
        return url;
    }, []);

    return { upload };
}
