import {
    BlockDeviceRequest,
    BlockRequest,
    EBlockRequestState,
    GetBlockUserListRequest,
    UnblockDeviceRequest,
    UnblockRequest,
} from '@gamebase-web-ops/game-user-block';
import { Auth } from '@gamebase-web/common';
import useFormatMessage from 'hooks/lang/useFormatMessage';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { BlockUserRepository } from 'repository';
import { notificationPush } from 'utils/notificationUtils';

export default function useBlockUserModel({
    projectId,
    defaultListQuery,
}: {
    projectId: string;
    banState?: EBlockRequestState;
    defaultListQuery?: Omit<GetBlockUserListRequest, 'projectId'>;
}) {
    const { messageFormatting } = useFormatMessage();
    const [listQuery, setListQuery] = useState<Omit<GetBlockUserListRequest, 'projectId'> | undefined>(
        defaultListQuery,
    );

    const queries = useQuery(['blockUserData', projectId, listQuery], () =>
        listQuery ? BlockUserRepository.getBlockUserList({ ...listQuery, projectId }) : undefined,
    );

    const reBan = useMutation(
        async () => {
            await BlockUserRepository.retry(new Auth({ projectId }));
        },
        {
            onSuccess: () => {
                notificationPush({
                    status: 'success',
                    message: messageFormatting('SUCCESS_REBAN'),
                });
            },
            onError: () => {
                notificationPush({
                    status: 'error',
                    message: messageFormatting('FAIL_REBAN'),
                });
            },
        },
    );

    const banMutation = useMutation(
        async (request: BlockRequest) => {
            await BlockUserRepository.block(request);
        },
        {
            onSuccess: () => {
                notificationPush({
                    status: 'success',
                    message: messageFormatting('SUCCESS_BAN'),
                });
            },
            onError: () => {
                notificationPush({
                    status: 'error',
                    message: messageFormatting('FAIL_BAN'),
                });
            },
        },
    );
    const banDeviceMutation = useMutation(
        async (request: BlockDeviceRequest) => {
            await BlockUserRepository.blockDevice(request);
        },
        {
            onSuccess: () => {
                notificationPush({
                    status: 'success',
                    message: messageFormatting('SUCCESS_BAN_DEVICE'),
                });
            },
            onError: () => {
                notificationPush({
                    status: 'error',
                    message: messageFormatting('FAIL_BAN_DEVICE'),
                });
            },
        },
    );

    const unbanMutation = useMutation(
        async (request: UnblockRequest) => {
            await BlockUserRepository.unblock(request);
        },
        {
            onSuccess: () => {
                notificationPush({
                    status: 'success',
                    message: messageFormatting('SUCCESS_UNBAN'),
                });
            },
            onError: () => {
                notificationPush({
                    status: 'error',
                    message: messageFormatting('FAIL_UNBAN'),
                });
            },
        },
    );
    const unbanDeviceMutation = useMutation(
        async (request: UnblockDeviceRequest) => {
            await BlockUserRepository.unblockDevice(request);
        },
        {
            onSuccess: () => {
                notificationPush({
                    status: 'success',
                    message: messageFormatting('SUCCESS_UNBAN_DEVICE'),
                });
            },
            onError: () => {
                notificationPush({
                    status: 'error',
                    message: messageFormatting('FAIL_UNBAN_DEVICE'),
                });
            },
        },
    );

    const { mutateAsync: reBanUser, isLoading: isReBan } = reBan;
    const { mutateAsync: banUser, isLoading: isBanUser } = banMutation;
    const { mutateAsync: banDevice, isLoading: isBanDevice } = banDeviceMutation;
    const { mutateAsync: unbanUser, isLoading: isUnbanUser } = unbanMutation;
    const { mutateAsync: unbanDevice, isLoading: isUnbanDevice } = unbanDeviceMutation;

    return {
        ...queries,
        setListQuery,
        listQuery,
        reBanUser,
        isReBan,
        banUser,
        isBanUser,
        banDevice,
        isBanDevice,
        unbanUser,
        isUnbanUser,
        unbanDevice,
        isUnbanDevice,
    };
}
