import useAuth from 'hooks/auth/useAuth';
import { notificationPush } from 'utils/notificationUtils';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthenticationRepository } from 'repository';
import { getMyDataRequestAction } from 'store/modules/account/action';
import { ProfileUpdateRequest } from '@gamebase-web-ops/authentication';
import useFormatMessage from 'hooks/lang/useFormatMessage';

export default function useAccount() {
    const dispatch = useDispatch();
    const { messageFormatting } = useFormatMessage();
    const account = useSelector<RootStore, RootStore['account']>(({ account }) => account);
    const { logoutRequest, originUrl } = useAuth();

    const getMyDataRequest = useCallback(() => {
        if (originUrl) return;
        dispatch(getMyDataRequestAction({ fallback: logoutRequest }));
    }, [originUrl, dispatch, logoutRequest]);

    const changeUserName = useCallback(
        async (param: IAccountEdit) => {
            await AuthenticationRepository.updateProfile(
                new ProfileUpdateRequest({
                    name: param.username,
                    id: account.account.id,
                }),
            );
            notificationPush({ status: 'success', message: messageFormatting('SUCCESS_USER_NAME_EDIT') });
            getMyDataRequest();
        },
        [account.account.id, getMyDataRequest],
    );

    const changeUserPassword = useCallback(
        async (param: IPasswordEdit) => {
            await AuthenticationRepository.updateProfile(
                new ProfileUpdateRequest({
                    password: param.newPassword,
                    id: account.account.id,
                }),
            );
            notificationPush({ status: 'success', message: messageFormatting('SUCCESS_PASSWORD_EDIT') });
        },
        [account.account.id],
    );

    return {
        getMyDataRequest,
        account,
        changeUserName,
        changeUserPassword,
    };
}
