import { Layout } from 'antd';
import classNames from 'classnames/bind';
import styles from './Layout.module.scss';

const cx = classNames.bind(styles);

const MyPageLayout: React.FC = ({ children }) => {
    return (
        <section className={cx('mainWrapper')}>
            <Layout.Content className={cx('contentMain')}>{children}</Layout.Content>
        </section>
    );
};

export default MyPageLayout;
