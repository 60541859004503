import { useQueries } from '../index';
import { useQuery } from 'react-query';
import { Authorization2Repository } from '../../repository';
import { Auth } from '@gamebase-web/common';

export default function usePermissions(projectId: string) {
    const { data } = useQuery(['my-permissions', projectId], () => {
        return Authorization2Repository.permissionList(new Auth({ projectId }));
    });

    return {
        permission: data?.result,
    };
}
