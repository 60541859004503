import { Transfer, Tree } from 'antd';
import styles from './RoleEditModal.module.scss';
import { css } from '@emotion/react';
import classNames from 'classnames/bind';
import { DataNode } from 'antd/lib/tree';
import { useCallback, useMemo } from 'react';
import { getDataSource, getTargetKeys } from 'utils/roles';
import useMyProjectList from 'hooks/project/useMyProjectList';
import { useAccount } from 'hooks';
import { MyProjectDto } from '@gamebase-web-ops/authorization/lib/v2';

interface RoleTransfer {
    rolePermissions: RolePermission[];
    setRolePermissions: React.Dispatch<React.SetStateAction<RolePermission[]>>;
    projectList: MyProjectDto[] | undefined;
}

export default function RoleTreeTransfer2({ rolePermissions, setRolePermissions, projectList }: RoleTransfer) {
    const cx = classNames.bind(styles);

    const { account } = useAccount();
    const { myProjectList } = useMyProjectList();
    const isMaster = useMemo(() => account.account.isMaster, [account]);
    const dataSource = useMemo(() => getDataSource(projectList), [projectList]);
    const targetKeys: string[] = useMemo(() => getTargetKeys(rolePermissions), [rolePermissions]);
    const hasNoPermission = useCallback((isManager: boolean | undefined) => !(isMaster || isManager), [isMaster]);
    const treeData: DataNode[] | undefined = useMemo(
        () =>
            projectList?.map(({ id, name }) => {
                const isManager = myProjectList?.find(({ id: projectId }: MyProjectDto) => id === projectId)?.isManager;
                const noPermission = hasNoPermission(isManager);
                return {
                    key: id,
                    title: name,
                    children: dataSource
                        .filter((source) => source.projectId === id)
                        .filter((child) => !targetKeys.includes(child.key))
                        .map((child) => ({
                            ...child,
                            disabled: noPermission,
                            selectable: noPermission,
                        })),
                    disabled: noPermission,
                    selectable: noPermission,
                };
            }),

        [dataSource, hasNoPermission, myProjectList, projectList, targetKeys],
    );

    return (
        <>
            <section className={cx('permissionHeader')}>
                <h1>권한</h1>
            </section>
            <div className={cx('section', 'permission')}>
                <Transfer
                    css={transfer}
                    operations={['추가', '삭제']}
                    style={{ width: '100%' }}
                    dataSource={dataSource}
                    targetKeys={targetKeys}
                    filterOption={(value, option) => option.name.match(value) !== null}
                    listStyle={{
                        width: 400,
                        height: 400,
                    }}
                    onChange={(targetKeys) => {
                        const nextRolePermissions = dataSource?.filter(({ projectId, service, level }) =>
                            targetKeys.find((key) => {
                                const [targetId, targetService, targetLv] = key.split(' ');
                                return (
                                    projectId === targetId && service === targetService && level === Number(targetLv)
                                );
                            }),
                        );
                        setRolePermissions(nextRolePermissions);
                    }}
                >
                    {({ direction, onItemSelect, onItemSelectAll, filteredItems }) => {
                        const filteredIds = new Set();
                        const filteredProjects = filteredItems.filter(({ projectId }) => {
                            if (filteredIds.has(projectId)) return false;
                            else {
                                filteredIds.add(projectId);
                                return true;
                            }
                        });

                        const filteredTreeData = filteredProjects.map((filteredProject) => {
                            const isManager = myProjectList?.find(
                                ({ id: projectId }: MyProjectDto) => filteredProject.projectId === projectId,
                            )?.isManager;
                            const noPermission = hasNoPermission(isManager);
                            return {
                                key: filteredProject.projectId,
                                title: filteredProject.projectName,
                                children: filteredItems
                                    .filter((item) => item.projectId === filteredProject.projectId)
                                    .map((child) => ({
                                        ...child,
                                        selectable: noPermission,
                                        disabled: noPermission,
                                    })),
                                disabled: noPermission,
                                selectable: noPermission,
                            };
                        });

                        return direction === 'left' ? (
                            <Tree
                                selectable={false}
                                checkable
                                treeData={treeData}
                                onCheck={(checkedKeys, info) => {
                                    if (info.node.children) {
                                        onItemSelectAll(
                                            info.node.children.map((child) => child.key.toString()),
                                            info.checked,
                                        );
                                    } else {
                                        onItemSelect(info.node.key.toString(), info.checked);
                                    }
                                }}
                            ></Tree>
                        ) : (
                            <Tree
                                checkable
                                defaultExpandAll
                                defaultExpandedKeys={projectList?.map((project) => project.id)}
                                selectable={false}
                                treeData={filteredTreeData}
                                onCheck={(checkedKeys, info) => {
                                    if (info.node.children) {
                                        onItemSelectAll(
                                            info.node.children.map((child: any) => child.key.toString()),
                                            info.checked,
                                        );
                                    } else {
                                        onItemSelect(info.node.key.toString(), info.checked);
                                    }
                                }}
                            ></Tree>
                        );
                    }}
                </Transfer>
            </div>
        </>
    );
}

const transfer = css`
    .ant-transfer-list-body {
        overflow-y: auto;
    }
`;
