import { handleActions } from 'redux-actions';
import { CurrentPage } from './action';

const initState: PaginationStore = {
    ranking: {
        currentPage: undefined,
    },
};

export default handleActions(
    {
        [CurrentPage.Set]: (prev, { payload }: { payload: { target: 'ranking'; currentPage: number } }) => {
            return {
                ...prev,
                [payload.target]: {
                    currentPage: payload.currentPage,
                },
            };
        },
    },
    initState,
);
