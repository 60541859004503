import { WebviewNoticeCreate } from 'dto/webviewNotice';
import { useCallback } from 'react';
import { NoticeRepository } from 'repository';
import {
    WebviewNoticeCreateRequest,
    WebviewNoticeUpdateRequest,
    WebviewNoticeDeployRequest,
    WebviewNoticeUndeployRequest,
} from '@gamebase-web-ops/notice';
import useWebviewNoticeListModel from './model/useWebviewNoticeList.model';

export default function useWebviewNotice(projectId: string) {
    const {
        data: webviewNoticeData,
        setPage,
        setFirstPageOrRefetch,
        filter,
        findByNoticeTitle,
    } = useWebviewNoticeListModel(projectId);

    const createWebviewNotice = useCallback(
        async (param: WebviewNoticeCreateRequest) => {
            const defaultLang = param.defaultLanguage;
            if (defaultLang) {
                if (!param.contents[defaultLang]) {
                    throw new Error('기본 노출 언어로 설정 되어있는 모든 값은 채워져야합니다.');
                }
                if (!param.mainImages[defaultLang]) {
                    throw new Error('기본 노출 언어로 설정 되어있는 모든 값은 채워져야합니다.');
                }
                if (!param.titles[defaultLang]) {
                    throw new Error('기본 노출 언어로 설정 되어있는 모든 값은 채워져야합니다.');
                }
            }
            await NoticeRepository.createWebviewNotice(new WebviewNoticeCreateRequest({ ...param, projectId }));
        },
        [projectId],
    );

    const updateWebviewNoticeContents = useCallback(
        async (id: string, param: WebviewNoticeCreate) => {
            const defaultLang = param.defaultLanguage;
            if (defaultLang) {
                if (!param.contents[defaultLang]) {
                    throw new Error('기본 노출 언어로 설정 되어있는 모든 값은 채워져야합니다.');
                }
                if (!param.mainImages[defaultLang]) {
                    throw new Error('기본 노출 언어로 설정 되어있는 모든 값은 채워져야합니다.');
                }
                if (!param.titles[defaultLang]) {
                    throw new Error('기본 노출 언어로 설정 되어있는 모든 값은 채워져야합니다.');
                }
            }
            await NoticeRepository.updateWebviewNotice(new WebviewNoticeUpdateRequest({ projectId, ...param, id }));
        },
        [projectId],
    );

    const publishNotice = useCallback(
        async (id: string) => {
            await NoticeRepository.deployWebviewNotice(new WebviewNoticeDeployRequest({ projectId, id }));
        },
        [projectId],
    );

    const unpublishNotice = useCallback(
        async (id: string) => {
            await NoticeRepository.undeployWebviewNotice(new WebviewNoticeUndeployRequest({ projectId, id }));
        },
        [projectId],
    );

    return {
        createWebviewNotice,
        webviewNoticeData,
        setPage,
        setFirstPageOrRefetch,
        filter,
        updateWebviewNoticeContents,
        publishNotice,
        unpublishNotice,
        findByNoticeTitle,
    };
}
