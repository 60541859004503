import { EServiceAuthLevel, EServiceAuthName, MyProjectDto } from '@gamebase-web-ops/authorization/lib/v2';

export const getDataSource = (projectList: MyProjectDto[] | undefined) => {
    const dataSource: any[] = [];
    projectList?.map(({ id: projectId, name }) => {
        Object.values(EServiceAuthName).map((service) => {
            Object.values(EServiceAuthLevel)
                .filter((level) => !isNaN(Number(level)))
                .map((level) => {
                    dataSource.push({
                        key: `${projectId} ${service} ${level}`,
                        title: `${service} ${Object.keys(EServiceAuthLevel)[
                            Object.values(EServiceAuthLevel).indexOf(level)
                        ].toLowerCase()}`,
                        projectId,
                        service,
                        level,
                        projectName: name,
                    });
                });
        });
    });

    return dataSource;
};

export const getTargetKeys = (rolePermission: RolePermission[]) =>
    rolePermission.map((info) => `${info.projectId} ${info.service} ${info.level}`);
