import { ItemDto } from '@gamebase-web-ops/item';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { ItemRepository } from 'repository';
import { ItemAllRequest } from '@gamebase-web-ops/item';

export default function useAllItems(projectId: string) {
    const queries = useQuery(`${projectId}-allItems`, () => ItemRepository.all(new ItemAllRequest({ projectId })));

    const itemHash = useMemo(() => {
        const map: Record<number, ItemDto> = {};
        queries.data?.result.map((item) => (map[item.itemId] = item));
        return map;
    }, [queries.data]);

    return {
        ...queries,
        itemHash,
        list: queries?.data?.result ?? [],
    };
}
