import { Route, Switch } from 'react-router-dom';
import { PageAuthViewController } from 'provider';
import {
    ItemPage,
    MailPage,
    ProjectDashBoard,
    UserPage,
    NoticePage,
    // MailDetailPage,
    AccountMerge,
    GameSystemPage,
    LogPage,
    VoidedPurchasePage,
    OrderPage,
    BlockUserPage,
    WebviewEventPage,
    PolicyPage,
    PromotionPage,
    PushPage,
    CouponPage,
    RealTimeServerPage,
    IngameServerPage,
    MetaDataPage,
    LoginServerPage,
    RangkingPage,
    AppVersionPage,
    AppReviewPage,
    ChattingPage,
    ReportPage,
    VideoPage,
    PlayerSearchPage,
    AbusingUserPage,
} from 'pages';

import { ProjectCategoryLayout } from 'components';
import { EServiceAuthLevel, EServiceAuthName, MyPermissionListDto } from '@gamebase-web-ops/authorization/lib/v2';
import Community from 'pages/project/community';
import GuildMain from 'pages/project/guild';
import usePermissions from '../hooks/auth/use-permissions';
import { useQueries } from '../hooks';
import { COMMON_PROJECT_ID } from '../constants/constants';
import { useMemo } from 'react';

const ProjectRoute = () => {
    const { projectId } = useQueries();
    const { permission } = usePermissions(projectId);
    const { permission: globalPermission } = usePermissions(COMMON_PROJECT_ID);

    const permissionDtos = useMemo(() => {
        const arr: MyPermissionListDto[] = [];
        if (permission) {
            arr.push(permission);
        }

        if (globalPermission) {
            arr.push(globalPermission);
        }

        return arr;
    }, [permission, globalPermission]);

    return (
        <Switch>
            <ProjectCategoryLayout>
                <Route path="/project/dashboard" render={() => <ProjectDashBoard />} />

                <Route
                    path="/project/log"
                    render={() => (
                        <PageAuthViewController
                            auths={[{ service: EServiceAuthName.LOG }]}
                            permissionDtos={permissionDtos}
                        >
                            <LogPage />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    exact
                    path={[
                        '/project/contents/notice/:type/:id',
                        '/project/contents/notice/:type',
                        '/project/contents/notice',
                    ]}
                    render={() => (
                        <PageAuthViewController
                            auths={[{ service: EServiceAuthName.NOTICE, level: EServiceAuthLevel.READ }]}
                            permissionDtos={permissionDtos}
                        >
                            <NoticePage permissionDtos={permissionDtos} />
                        </PageAuthViewController>
                    )}
                />

                <Route
                    path={['/project/contents/event/:type', '/project/contents/event']}
                    render={() => (
                        <PageAuthViewController
                            auths={[{ service: EServiceAuthName.EVENT, level: EServiceAuthLevel.READ }]}
                            permissionDtos={permissionDtos}
                        >
                            <WebviewEventPage permissionDtos={permissionDtos} />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    exact
                    path={['/project/contents/mail', '/project/contents/mail/:mailType']}
                    render={() => (
                        <PageAuthViewController
                            auths={[{ service: EServiceAuthName.MAIL, level: EServiceAuthLevel.READ }]}
                            permissionDtos={permissionDtos}
                        >
                            <MailPage />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    path={['/project/contents/coupons', '/project/contents/coupon']}
                    render={() => (
                        <PageAuthViewController
                            auths={[{ service: EServiceAuthName.COUPON, level: EServiceAuthLevel.READ }]}
                            permissionDtos={permissionDtos}
                        >
                            <CouponPage />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    path="/project/contents/push"
                    render={() => (
                        <PageAuthViewController
                            auths={[{ service: EServiceAuthName.PUSH, level: EServiceAuthLevel.READ }]}
                            permissionDtos={permissionDtos}
                        >
                            <PushPage />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    path="/project/contents/gameSystem"
                    render={() => (
                        <PageAuthViewController
                            auths={[{ service: EServiceAuthName.PROJECT, level: EServiceAuthLevel.READ }]}
                            permissionDtos={permissionDtos}
                        >
                            <GameSystemPage />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    path="/project/contents/item"
                    render={() => (
                        <PageAuthViewController
                            auths={[{ service: EServiceAuthName.ITEM, level: EServiceAuthLevel.READ }]}
                            permissionDtos={permissionDtos}
                        >
                            <ItemPage />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    path={['/project/contents/rank/:id', '/project/contents/rank']}
                    render={() => (
                        <PageAuthViewController
                            auths={[{ service: EServiceAuthName.RANK, level: EServiceAuthLevel.READ }]}
                            permissionDtos={permissionDtos}
                        >
                            <RangkingPage />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    path="/project/contents/app-review"
                    render={() => (
                        <PageAuthViewController
                            auths={[{ service: EServiceAuthName.APP_REVIEW, level: EServiceAuthLevel.READ }]}
                            permissionDtos={permissionDtos}
                        >
                            <AppReviewPage />
                        </PageAuthViewController>
                    )}
                />

                <Route
                    path="/project/contents/chat"
                    render={() => (
                        <PageAuthViewController
                            auths={[{ service: EServiceAuthName.CHAT, level: EServiceAuthLevel.READ }]}
                            permissionDtos={permissionDtos}
                        >
                            <ChattingPage />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    path="/project/contents/video"
                    render={() => (
                        <PageAuthViewController
                            auths={[{ service: EServiceAuthName.EVENT, level: EServiceAuthLevel.READ }]}
                            permissionDtos={permissionDtos}
                        >
                            <VideoPage />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    path="/project/contents/community"
                    render={() => (
                        <PageAuthViewController
                            auths={[{ service: EServiceAuthName.EVENT, level: EServiceAuthLevel.READ }]}
                            permissionDtos={permissionDtos}
                        >
                            <Community />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    path={['/project/promotion/:query', '/project/promotion']}
                    render={() => (
                        <PageAuthViewController
                            auths={[{ service: EServiceAuthName.CROSS_PROMOTION, level: EServiceAuthLevel.READ }]}
                            permissionDtos={permissionDtos}
                        >
                            <PromotionPage />
                        </PageAuthViewController>
                    )}
                />
                {/* <Route
                    path="/project/mail/detail/:id"
                    render={() => (
                        <PageAuthViewController service={EServiceAuthName.MAIL} level={EServiceAuthLevel.READ}>
                            <MailDetailPage />
                        </PageAuthViewController>
                    )}
                /> */}

                <Route
                    path="/project/user/userInfo/:pid"
                    render={() => (
                        <PageAuthViewController
                            auths={[{ service: EServiceAuthName.USER, level: EServiceAuthLevel.READ }]}
                            permissionDtos={permissionDtos}
                        >
                            <UserPage permissionDtos={permissionDtos} />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    exact
                    path="/project/user/userInfo"
                    render={() => (
                        <PageAuthViewController
                            auths={[{ service: EServiceAuthName.USER, level: EServiceAuthLevel.READ }]}
                            permissionDtos={permissionDtos}
                        >
                            <UserPage permissionDtos={permissionDtos} />
                        </PageAuthViewController>
                    )}
                ></Route>
                <Route
                    path="/project/user/accountMerge"
                    render={() => (
                        <PageAuthViewController
                            auths={[{ service: EServiceAuthName.ACCOUNT_MERGE, level: EServiceAuthLevel.READ }]}
                            permissionDtos={permissionDtos}
                        >
                            <AccountMerge />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    path="/project/user/order"
                    render={() => (
                        <PageAuthViewController
                            auths={[{ service: EServiceAuthName.ORDER, level: EServiceAuthLevel.READ }]}
                            permissionDtos={permissionDtos}
                        >
                            <OrderPage />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    path="/project/user/voidedPurchase"
                    render={() => (
                        <PageAuthViewController
                            auths={[{ service: EServiceAuthName.VOIDED_PURCHASE, level: EServiceAuthLevel.READ }]}
                            permissionDtos={permissionDtos}
                        >
                            <VoidedPurchasePage />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    path="/project/user/report"
                    render={() => (
                        <PageAuthViewController
                            auths={[{ service: EServiceAuthName.USER, level: EServiceAuthLevel.READ }]}
                            permissionDtos={permissionDtos}
                        >
                            <ReportPage />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    path="/project/user/blockUser"
                    render={() => (
                        <PageAuthViewController
                            auths={[{ service: EServiceAuthName.BLOCK_USER, level: EServiceAuthLevel.READ }]}
                            permissionDtos={permissionDtos}
                        >
                            <BlockUserPage />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    path="/project/user/abusingUser"
                    render={() => (
                        <PageAuthViewController
                            auths={[{ service: EServiceAuthName.USER, level: EServiceAuthLevel.READ }]}
                            permissionDtos={permissionDtos}
                        >
                            <AbusingUserPage />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    path="/project/maintenance/ingameMaintenance"
                    render={() => (
                        <PageAuthViewController
                            auths={[{ service: EServiceAuthName.NOTICE, level: EServiceAuthLevel.READ }]}
                            permissionDtos={permissionDtos}
                        >
                            <IngameServerPage permissionDtos={permissionDtos} />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    path="/project/maintenance/serverMaintenance"
                    render={() => (
                        <PageAuthViewController
                            auths={[{ service: EServiceAuthName.PROJECT, level: EServiceAuthLevel.READ }]}
                            permissionDtos={permissionDtos}
                        >
                            <RealTimeServerPage />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    path="/project/maintenance/appsetting"
                    render={() => (
                        <PageAuthViewController
                            auths={[{ service: EServiceAuthName.APP_SETTING, level: EServiceAuthLevel.READ }]}
                            permissionDtos={permissionDtos}
                        >
                            <AppVersionPage />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    path="/project/marketing/metaData"
                    render={() => (
                        <PageAuthViewController
                            auths={[
                                { service: EServiceAuthName.GAME_MARKETING_MEATADATA, level: EServiceAuthLevel.READ },
                            ]}
                            permissionDtos={permissionDtos}
                        >
                            <MetaDataPage />
                        </PageAuthViewController>
                    )}
                />

                <Route
                    path="/project/policy/policy"
                    render={() => (
                        <PageAuthViewController
                            auths={[{ service: EServiceAuthName.POLICY, level: EServiceAuthLevel.READ }]}
                            permissionDtos={permissionDtos}
                        >
                            <PolicyPage />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    path="/project/development/loginServer"
                    render={() => (
                        <PageAuthViewController
                            auths={[{ service: EServiceAuthName.USER, level: EServiceAuthLevel.READ }]}
                            permissionDtos={permissionDtos}
                        >
                            <LoginServerPage />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    path={['/project/contents/player-search/:id', '/project/contents/player-search']}
                    render={() => (
                        <PageAuthViewController
                            auths={[{ service: EServiceAuthName.PLAYER_SEARCH, level: EServiceAuthLevel.READ }]}
                            permissionDtos={permissionDtos}
                        >
                            <PlayerSearchPage />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    path={['/project/contents/guild/:id', '/project/contents/guild']}
                    render={() => (
                        <PageAuthViewController
                            auths={[{ service: EServiceAuthName.GUILD, level: EServiceAuthLevel.READ }]}
                            permissionDtos={permissionDtos}
                        >
                            <GuildMain />
                        </PageAuthViewController>
                    )}
                />
            </ProjectCategoryLayout>
        </Switch>
    );
};

export default ProjectRoute;
