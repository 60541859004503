import {
    ProjectCodeAllRequest,
    ProjectCodeAllResponse,
    ProjectCodeCreateRequest,
    ProjectCodeDeleteRequest,
    EProjectCodeType,
} from '@gamebase-web-ops/code';
import { COMMON_PROJECT_ID } from '../../constants/constants';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { CodeRepository } from 'repository';
import { notificationPush } from 'utils/notificationUtils';
import useFormatMessage from 'hooks/lang/useFormatMessage';

interface MenuList {
    data: ProjectCodeAllResponse<string> | undefined;
}
export default function useProjectMenu(projectId: string) {
    const queryClient = useQueryClient();
    const { messageFormatting } = useFormatMessage();

    const { data: allMenuList } = useQuery('useProjectMenuAll', () =>
        CodeRepository.allProjectCodes<string>(
            new ProjectCodeAllRequest({
                projectId: COMMON_PROJECT_ID,
                codeType: EProjectCodeType.PROJECT_MENU,
            }),
        ),
    );

    const { data: menuList }: MenuList = useQuery(['project', projectId], () =>
        CodeRepository.allProjectCodes<string>(
            new ProjectCodeAllRequest({
                projectId,
                codeType: EProjectCodeType.PROJECT_MENU,
            }),
        ),
    );

    const createProjectMenuMutation = useMutation(
        async (payload: ProjectCodeCreateRequest) => {
            await CodeRepository.createProjectCode(new ProjectCodeCreateRequest(payload));
        },
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries(['project', projectId]);
                notificationPush({ status: 'success', message: messageFormatting('SUCCESS_MENU_CREATE') });
            },
        },
    );

    const deleteProjectMenuMutation = useMutation(
        async (payload: ProjectCodeDeleteRequest) => {
            await CodeRepository.deleteProjectCode(new ProjectCodeDeleteRequest(payload));
        },
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries(['project', projectId]);
                notificationPush({ status: 'success', message: messageFormatting('SUCCESS_MENU_DELETE') });
            },
        },
    );

    const projectAllMenuList = (allMenuList ? allMenuList.result : []).sort((a, b) => (a.desc > b.desc ? 1 : -1));
    const projectMenuList = (menuList ? menuList.result : []).sort((a, b) => (a.desc > b.desc ? 1 : -1));

    const { mutate: deleteProjectMenu } = deleteProjectMenuMutation;
    const { mutate: createProjectMenu } = createProjectMenuMutation;

    return { projectAllMenuList, projectMenuList, deleteProjectMenu, createProjectMenu };
}
