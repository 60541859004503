import { useQueries } from '../../../hooks';
import useGuild from '../../../hooks/guild/use-guild';
import GuildInfo from './guild-info';
import GuildSearch from './guild-search';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import GuildMember from './guild-member';
import GuildApplicant from './guild-applicant';
import { Spin, Tabs } from 'antd';
import { Common } from '../../../styles/common';

export default function GuildMain() {
    const { projectId } = useQueries();
    const { id } = useParams<{ id: string }>();
    const {
        guild,
        withdraw,
        isWithdrawLoading,
        setGuildFilter,
        changeGuildName,
        isNameChangeLoading,
        refetchGuild,
        isGuildLoading,
        isRefetching,
        isGuildError,
    } = useGuild();

    useEffect(() => {
        if (id) setGuildFilter({ projectId, id });
    }, [id, projectId, setGuildFilter]);

    return (
        <>
            <Spin spinning={isGuildLoading || isRefetching}>
                <GuildSearch
                    onSubmit={(payload) => {
                        setGuildFilter({ projectId, ...payload });
                    }}
                ></GuildSearch>

                {guild?.result && !isGuildError && (
                    <div css={Common.searchWrapper}>
                        <Tabs
                            size="middle"
                            color="primary"
                            type="line"
                            items={[
                                {
                                    label: '길드',
                                    key: 'guild',
                                    children: (
                                        <GuildInfo
                                            guildDto={guild.result}
                                            onNameChange={(name) => {
                                                changeGuildName({
                                                    newName: name,
                                                    oldName: guild.result.name,
                                                    id: guild.result.id,
                                                    projectId,
                                                });
                                            }}
                                            isNameChangeLoading={isNameChangeLoading}
                                            withdraw={() =>
                                                withdraw({
                                                    projectId,
                                                    id: guild?.result.id,
                                                })
                                            }
                                            isWithdrawLoading={isWithdrawLoading}
                                        />
                                    ),
                                },
                                {
                                    label: '멤버',
                                    key: 'member',
                                    children: (
                                        <GuildMember
                                            memberList={guild.result.memberList}
                                            masterId={guild.result.masterAccountId}
                                            guildId={guild.result.id}
                                            refetchGuild={refetchGuild}
                                        />
                                    ),
                                },
                                {
                                    label: '가입 요청',
                                    key: 'applicant',
                                    children: (
                                        <GuildApplicant
                                            guildId={guild.result.id}
                                            applicants={guild.result.applicantList}
                                            refetchGuild={refetchGuild}
                                        />
                                    ),
                                },
                            ]}
                        ></Tabs>
                    </div>
                )}
            </Spin>
        </>
    );
}
