import { CreateBlockTemplateRequest, UpdateBlockTemplateRequest } from '@gamebase-web-ops/game-user-block';
import useFormatMessage from 'hooks/lang/useFormatMessage';
import { useMutation, useQuery } from 'react-query';
import { BlockUserRepository } from 'repository';
import { notificationPush } from 'utils/notificationUtils';

export default function useBlockTemplateModel({ projectId }: { projectId: string }) {
    const { messageFormatting } = useFormatMessage();
    const queries = useQuery(['blockTemplate', projectId], () => BlockUserRepository.getBlockTemplates({ projectId }));

    const createMutation = useMutation(
        async (request: CreateBlockTemplateRequest) => {
            await BlockUserRepository.createBlockTemplate(request);
        },
        {
            onSuccess: () => {
                notificationPush({
                    status: 'success',
                    message: messageFormatting('SUCCESS_TEMPLATE_CREATE'),
                });
            },
            onError: (err: any) => {
                notificationPush({
                    status: 'error',
                    message: err?.message ?? messageFormatting('FAIL_TEMPLATE_CREATE'),
                });
            },
        },
    );

    const updateMutation = useMutation(
        async (request: UpdateBlockTemplateRequest) => await BlockUserRepository.updateBlockTemplate(request),
        {
            onSuccess: () => {
                notificationPush({
                    status: 'success',
                    message: messageFormatting('SUCCESS_TEMPLATE_EDIT'),
                });
            },
            onError: (err: any) => {
                notificationPush({
                    status: 'error',
                    message: err?.message ?? messageFormatting('FAIL_TEMPLATE_EDIT'),
                });
            },
        },
    );

    const { mutateAsync: createTemplate, isLoading: isCreateTemplate, isError: createError } = createMutation;
    const { mutateAsync: updateTemplate, isLoading: isUpdateTemplate } = updateMutation;

    return {
        ...queries,
        createTemplate,
        updateTemplate,
        isCreateTemplate,
        isUpdateTemplate,
        createError,
    };
}
